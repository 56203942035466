import React from 'react';
import PropTypes from 'prop-types';
import Wrapper, { Container } from './styles';

const FullPage = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      <Container>
        {children}
      </Container>
    </Wrapper>
  );
};

FullPage.defaultProps = {
  children: null,
};

FullPage.propTypes = {
  children: PropTypes.node,
};

export default FullPage;
