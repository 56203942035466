import { message } from 'antd';
import { isUndefined } from 'lodash';

const apiMiddleware = (store) => (next) => async (action) => {
  if (isUndefined(action.types)) {
    next(action);
    return;
  }

  const { ERROR, SUCCESS, REQUEST } = action.types;

  const newAction = action;
  delete newAction.types;

  next({
    type: REQUEST,
    payload: [],
    loading: true,
  });

  try {
    const response = await action.action();

    if (!isUndefined(response.success) && response.success === 0) {
      throw new Error(response.msm);
    }

    if (action.onSuccess) {
      action.onSuccess(store.dispatch, response);
    }

    store.dispatch({
      ...newAction,
      type: SUCCESS,
      payload: response,
      loading: false,
    });
  } catch (err) {
    if (action.onError) {
      action.onError(store.dispatch, err);
    }

    store.dispatch({
      ...newAction,
      type: ERROR,
      payload: [],
      loading: false,
    });

    message.error(`Ocurrió un error inesperado: ${err.message}`);
  }
};

export default apiMiddleware;
