import { LogoutOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const LogoutIcon = styled(LogoutOutlined)`
  font-size: 1.2rem;
  color: ${(props) => props.theme.colors.main};
`;

export const LogoutContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  &:hover{
    opacity: 0.8;
  }
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  border: 2px solid ${(props) => props.theme.colors.main};
  background: no-repeat center center;
  background-color: ${(props) => props.theme.colors.main};
  background-size: cover;
  background-image: url(${(props) => props.src});
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default Wrapper;
