import React from 'react';
import assets from '../../shared/assets';
import HeaderUserInfo from '../HeaderUserInfo';
import Wrapper, { Logo, UserInfo } from './styles';

const HeaderContents = () => (
  <Wrapper>
    <Logo src={assets.logo} />
    <UserInfo>
      <HeaderUserInfo />
    </UserInfo>
  </Wrapper>
);

export default HeaderContents;
