import { useDispatch, useSelector } from 'react-redux';
import { getFleetListing } from '../../redux/fleet/actions';
import fleetDict from '../constants/fleetDict';

const useFleetListing = (type) => {
  const dict = fleetDict[type];
  const dispatch = useDispatch();
  const { fleet } = useSelector((reduxState) => reduxState);

  const fetch = () => {
    dispatch(getFleetListing(type));
  };

  return {
    loading: fleet.loading,
    payload: fleet[type],
    fetch,
    dict,
  };
};

export default useFleetListing;
