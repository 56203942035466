import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';

import AuthenticatedRoutes from './authenticated';
import GuestRoutes from './guest';

const MainRouter = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Router>
      <Switch>
        { isAuthenticated ? <AuthenticatedRoutes /> : <GuestRoutes />}
      </Switch>
    </Router>
  );
};

export default MainRouter;
