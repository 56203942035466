import React from 'react';
import PropTypes from 'prop-types';
import Wrapper, { Container, Logo, Title } from './styles';
import assets from '../../shared/assets';

const SignForm = (props) => {
  const { children, title } = props;

  return (
    <Wrapper>
      <Logo src={assets.logo} />
      <Title>
        {title}
      </Title>
      <Container>
        {children}
      </Container>
    </Wrapper>
  );
};

SignForm.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default SignForm;
