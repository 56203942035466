/* eslint-disable no-plusplus */
import {
  each, filter, values,
} from 'lodash';

export const slugify = (strProp) => {
  let str = strProp;
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

/* eslint-disable import/prefer-default-export */
const sanitizeText = (txt) => (
  slugify(txt.toString())
);

export const useFuzzySearchByKeys = (objects, text) => {
  if (text) {
    const sanitizedText = sanitizeText(text);

    const filteredObjects = filter(objects, (object) => {
      let shouldShow = false;
      const objectValues = values(object);
      each(objectValues, (value) => {
        const sanitizedValue = sanitizeText(value);
        if (
          sanitizedValue.indexOf(sanitizedText) > -1
            || sanitizedText.indexOf(sanitizedValue) > -1
        ) {
          shouldShow = true;
        }
      });

      return shouldShow;
    });

    return filteredObjects;
  }

  return objects;
};
