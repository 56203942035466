/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Button } from 'antd';

export const SignInButton = styled(Button).attrs({
  type: 'primary',
  size: 'large',
})`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
