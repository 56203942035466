import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import useCurrentUser from '../../shared/hooks/useCurrentUser';
import Wrapper, { Avatar, LogoutContainer, LogoutIcon } from './styles';

const HeaderUserInfo = () => {
  const { logout } = useAuth0();
  const user = useCurrentUser();

  return (
    <Wrapper>
      <Avatar src={user.picture} />
      <LogoutContainer
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        <LogoutIcon />
      </LogoutContainer>
    </Wrapper>
  );
};

export default HeaderUserInfo;
