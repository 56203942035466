/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { map } from 'lodash';

const EditableCell = (props) => {
  const {
    defaultValue,
    onSave,
    editableCombo,
  } = props;

  const [value, setValue] = useState(defaultValue);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleSave = () => {
    onSave(value);
    setIsEditing(false);
  };

  const renderInput = () => {
    if (!editableCombo) {
      return (
        <Input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleSave}
          rules={[{ required: true, message: 'Este campo es requerido.' }]}
        />
      );
    }

    return (
      <Select
        onChange={(val) => {
          onSave(val);
          setValue(val);
          setIsEditing(false);
        }}
        style={{ width: '100%' }}
        value={value}
      >
        {map(editableCombo, (opt) => (
          <Select.Option value={opt} key={opt}>
            {opt}
          </Select.Option>
        ))}
      </Select>
    );
  };

  if (isEditing) {
    return renderInput();
  }

  return (
    <div onClick={() => setIsEditing(true)}>
      {value}
    </div>
  );
};

EditableCell.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  editableCombo: PropTypes.array || undefined,
};

EditableCell.defaultProps = {
  editableCombo: undefined,
};

export default EditableCell;
