const antdTranslations = {
  Table: {
    emptyText: 'Sin datos',
    filterTitle: 'Filtrar menú',
    filterConfirm: 'OK',
    filterReset: 'Resetear',
    filterEmptyText: 'Sin filtros',
    selectAll: 'Seleccionar página actual',
    selectInvert: 'Invertir página actual',
    selectionAll: 'Seleccionar todo',
    sortTitle: 'Ordenar',
    expand: 'Expandir',
    collapse: 'Colapsar',
    triggerDesc: 'Ordena descendentemente',
    triggerAsc: 'Ordena ascendentemente',
    cancelSort: 'Cancelar orden',
  },
};

export default antdTranslations;
