import { CheckCircleTwoTone, CloseCircleTwoTone, EnvironmentOutlined } from '@ant-design/icons';
import React from 'react';
import { post } from '../http';

const fleetDict = {
  users: {
    endpoints: {
      ADD: 'add-user',
      UPDATE: 'update-user',
      LIST: 'list-user',
      DELETE: 'del-user',
    },
    key: 'users',
    singular: 'Usuario',
    plural: 'usuarios',
    addNewLabel: 'Añadir usuario',
    hideEnabled: true,
    form: [
      {
        type: 'email',
        name: 'mail',
        label: 'Correo electrónico',
        required: true,
      },
      {
        type: 'text',
        name: 'name',
        label: 'Nombre',
        required: true,
      },
      {
        type: 'text',
        name: 'last_name',
        label: 'Apellido',
        required: true,
      },
      {
        type: 'tel',
        name: 'phone',
        label: 'Teléfono',
        required: true,
      },
      {
        type: 'combo',
        name: 'department',
        label: 'Departamentos',
        required: true,
        options: ['OP'],
        default: 'OP',
      },
      {
        type: 'combo',
        name: 'permits',
        label: 'Permisos',
        required: true,
        options: ['ALL'],
        default: 'ALL',
      },
      {
        type: 'boolean',
        name: 'authorized',
        label: '¿Autorizado?',
        default: true,
      },
    ],
    columns: [
      {
        simple: true,
        title: 'E-mail',
        name: 'mail',
        key: 'mail',
      },
    ],
    submitForm: 'Añadir usuario',
  },
  cities: {
    endpoints: {
      ADD: 'add-city',
      UPDATE: 'update-city',
      LIST: 'listing-cities',
    },
    key: 'city',
    singular: 'Ciudad',
    plural: 'ciudades',
    addNewLabel: 'Añadir nueva ciudad',
    hideDelete: true,
    form: [
      {
        type: 'text',
        name: 'city',
        label: 'Nombre de la ciudad',
        required: true,
      },
    ],
    submitForm: 'Añadir ciudad',
  },
  systems: {
    endpoints: {
      ADD: 'add-system',
      UPDATE: 'update-system',
      LIST: 'listing-system',
    },
    key: 'system',
    singular: 'Sistema',
    plural: 'sistemas',
    addNewLabel: 'Añadir nuevo sistema',
    hideDelete: true,
    columns: [
      {
        type: 'cities',
        title: 'Ciudad',
        nameKey: 'city',
        key: 'city_id',
      },
    ],
    form: [
      {
        type: 'fetched-select',
        label: 'Ciudad',
        name: 'city_id',
        key: 'city',
        required: true,
        fetcher: async () => post('/listing-cities'),
      },
      {
        type: 'text',
        name: 'system',
        label: 'Nombre del sistema',
        required: true,
      },
      {
        type: 'boolean',
        name: 'enabled',
        label: '¿Habilitado?',
        default: true,
      },
    ],
    submitForm: 'Añadir sistema',
  },
  subsystems: {
    endpoints: {
      ADD: 'add-subsystem',
      UPDATE: 'update-subsystem',
      LIST: 'listing-subsystem',
      DELETE: 'del-subsystem',
    },
    key: 'subsystem',
    singular: 'Sub-sistema',
    plural: 'sub-sistemas',
    addNewLabel: 'Añadir nuevo sub-sistema',
    hideDelete: true,
    columns: [
      {
        type: 'cities',
        title: 'Ciudad',
        nameKey: 'city',
        key: 'city_id',
      },
      {
        type: 'systems',
        title: 'Sistema',
        nameKey: 'system',
        key: 'system_id',
      },
    ],
    form: [
      {
        type: 'fetched-select',
        label: 'Ciudad',
        key: 'city',
        name: 'city_id',
        required: true,
        fetcher: async () => post('/listing-cities'),
      },
      {
        type: 'fetched-select',
        label: 'Sistema',
        name: 'system_id',
        key: 'system',
        required: true,
        filterBy: 'city_id',
        fetcher: async () => post('/listing-system'),
      },
      {
        type: 'text',
        name: 'subsystem',
        label: 'Nombre del sub-sistema',
        required: true,
      },
      {
        type: 'boolean',
        name: 'enabled',
        label: '¿Habilitado?',
        default: true,
      },
    ],
    submitForm: 'Añadir sub-sistema',
  },
  lines: {
    endpoints: {
      ADD: 'add-line',
      UPDATE: 'update-line',
      LIST: 'listing-line',
      DELETE: 'del-line',
    },
    key: 'line',
    singular: 'Línea',
    plural: 'líneas',
    addNewLabel: 'Añadir nueva línea',
    hideDelete: true,
    columns: [
      {
        type: 'cities',
        title: 'Ciudad',
        nameKey: 'city',
        key: 'city_id',
      },
      {
        type: 'systems',
        title: 'Sistema',
        nameKey: 'system',
        key: 'system_id',
      },
      {
        type: 'subsystems',
        title: 'Sub-sistema',
        nameKey: 'subsystem',
        key: 'subsystem_id',
      },
    ],
    form: [
      {
        type: 'fetched-select',
        label: 'Ciudad',
        name: 'city_id',
        key: 'city',
        required: true,
        fetcher: async () => post('/listing-cities'),
      },
      {
        type: 'fetched-select',
        label: 'Sistema',
        name: 'system_id',
        key: 'system',
        required: true,
        filterBy: 'city_id',
        fetcher: async () => post('/listing-system'),
      },
      {
        type: 'fetched-select',
        label: 'Sub-sistema',
        name: 'subsystem_id',
        key: 'subsystem',
        required: true,
        filterBy: 'system_id',
        fetcher: async () => post('/listing-subsystem'),
      },
      {
        type: 'text',
        name: 'line',
        label: 'Nombre de la línea',
        required: true,
      },
      {
        type: 'boolean',
        name: 'enabled',
        label: '¿Habilitada?',
        default: true,
      },
    ],
    submitForm: 'Añadir línea',
  },
  units: {
    endpoints: {
      ADD: 'add-unit',
      UPDATE: 'update-unit',
      LIST: 'listing-unit',
      DELETE: 'delete-unit',
    },
    key: 'unit',
    singular: 'Unidad',
    plural: 'unidades',
    addNewLabel: 'Añadir nueva unidad',
    hideEnabled: false,
    columns: [
      {
        key: 'latlng',
        title: 'Posición',
        render: (_text, record) => {
          if (!record.lat || !record.lon || !record.is_station) {
            return null;
          }

          const coords = [record.lat, record.lon];
          const url = `https://maps.google.com/?z=15&q=${coords.join(',')}`;

          return (
            <a href={url} target="_blank" rel="noreferrer">
              <EnvironmentOutlined />
            </a>
          );
        },
      },
      {
        key: 'is_station',
        title: '¿Es Estación?',
        render: (_text, record) => {
          const isStation = record.is_station;

          if (isStation) {
            return (
              <CheckCircleTwoTone />
            );
          }
          return (
            <CloseCircleTwoTone twoToneColor="red" />
          );
        },
      },
      {
        type: 'cities',
        title: 'Ciudad',
        nameKey: 'city',
        key: 'city_id',
      },
      {
        type: 'systems',
        title: 'Sistema',
        nameKey: 'system',
        key: 'system_id',
      },
      {
        type: 'subsystems',
        title: 'Sub-sistema',
        nameKey: 'subsystem',
        key: 'subsystem_id',
      },
      {
        type: 'lines',
        title: 'Línea',
        nameKey: 'line',
        key: 'line_id',
      },
    ],
    form: [
      {
        type: 'fetched-select',
        key: 'city',
        name: 'city_id',
        label: 'Ciudad',
        required: true,
        fetcher: async () => post('/listing-cities'),
      },
      {
        type: 'fetched-select',
        label: 'Sistema',
        name: 'system_id',
        key: 'system',
        required: true,
        filterBy: 'city_id',
        fetcher: async () => post('/listing-system'),
      },
      {
        type: 'fetched-select',
        label: 'Sub-sistema',
        name: 'subsystem_id',
        key: 'subsystem',
        required: true,
        filterBy: 'system_id',
        fetcher: async () => post('/listing-subsystem'),
      },
      {
        type: 'fetched-select',
        label: 'Línea',
        name: 'line_id',
        key: 'line',
        required: true,
        filterBy: 'subsystem_id',
        fetcher: async () => post('/listing-line'),
      },
      {
        type: 'boolean',
        name: 'is_station',
        label: '¿Es estación?',
        default: true,
      },
      {
        type: 'number',
        name: 'lat',
        label: 'Latitud',
        if: 'is_station',
      },
      {
        type: 'number',
        name: 'lon',
        label: 'Longitud',
        if: 'is_station',
      },
      {
        type: 'text',
        name: 'unit',
        label: 'Nombre de la unidad',
        required: true,
      },
      {
        type: 'boolean',
        name: 'enabled',
        label: '¿Habilitada?',
        default: true,
      },
    ],
    submitForm: 'Añadir unidad',
  },
  sims: {
    endpoints: {
      ADD: 'add-sim',
      LIST: 'listing-sims',
      DELETE: 'del-sim',
      UPDATE: 'update-sim',
    },
    key: 'sim',
    singular: 'SIM',
    plural: 'SIMs',
    addNewLabel: 'Añadir nueva SIM',
    hideUpdate: true,
    hideEnabled: true,
    hideDefaultColumns: true,
    columns: [
      {
        key: 'icc',
        simple: true,
        title: 'ICC',
      },
      {
        key: 'isdn',
        simple: true,
        title: 'ISDN',
      },
      {
        key: 'company',
        simple: true,
        title: 'Compañía',
      },
      {
        key: 'status',
        simple: true,
        title: 'Estatus',
        editable: true,
        width: 100,
        editableCombo: ['Ready', 'Inactive', 'Lost', 'Damaged'],
      },
      {
        key: 'purchased',
        simple: true,
        title: 'Fecha de creación',
      },
    ],
    form: [
      {
        type: 'number',
        name: 'icc',
        label: 'ICC',
        required: true,
        maxLength: 19,
      },
      {
        type: 'number',
        name: 'isdn',
        label: 'ISDN',
        maxLength: 19,
      },
      {
        type: 'combo',
        name: 'company',
        label: 'Compañía',
        required: true,
        options: ['Telcel', 'Altan', 'ATT'],
      },
      {
        type: 'combo',
        name: 'status',
        label: 'Estatus',
        required: true,
        options: ['Ready', 'Inactive', 'Lost', 'Damaged'],
        default: 'Ready',
      },
      {
        type: 'datetime-local',
        name: 'purchased',
        label: 'Comprado el',
        required: true,
        default: new Date().toDateString(),
      },
    ],
    submitForm: 'Añadir nueva SIM',
  },
  nodes: {
    endpoints: {
      ADD: 'add-node',
      UPDATE: 'update-node',
      LIST: 'listing-nodes',
      DELETE: 'del-node',
    },
    key: 'node',
    singular: 'Nodo',
    plural: 'nodos',
    addNewLabel: 'Añadir nuevo nodo',
    hideUpdate: true,
    hideEnabled: true,
    disableNameEdition: true,
    columns: [
      {
        key: 'mac_eth',
        title: 'Mac Ethernet',
        simple: true,
      },
      {
        key: 'mac_w1',
        title: 'Mac W1',
        simple: true,
      },
      {
        key: 'mac_w2',
        title: 'Mac W2',
        simple: true,
      },
      {
        key: 'mac_w2',
        title: 'Mac W2',
        simple: true,
      },
      {
        key: 'imei',
        title: 'IMEI',
        simple: true,
      },
      // {
      //   key: 'sim',
      //   title: 'SIM',
      //   simple: true,
      // },
      {
        key: 'serial',
        title: 'Serial',
        simple: true,
      },
      {
        key: 'comments',
        title: 'Comentarios',
        simple: true,
      },
    ],
    form: [
      {
        type: 'text',
        name: 'name',
        label: 'Nombre del nodo',
        required: true,
      },
      {
        type: 'text',
        name: 'mac_eth',
        label: 'MAC ethernet',
        required: true,
      },
      {
        type: 'text',
        name: 'mac_w1',
        label: 'MAC W1',
        required: true,
      },
      {
        type: 'text',
        name: 'mac_w2',
        label: 'MAC W2',
        required: true,
      },
      {
        type: 'text',
        name: 'imei',
        label: 'IMEI',
        required: true,
      },
      {
        type: 'text',
        name: 'serial',
        label: 'Serial',
        required: true,
      },
      {
        type: 'combo',
        name: 'model',
        label: 'Modelo',
        required: true,
        options: ['AP42', 'AP62', 'M4'],
      },
      {
        type: 'datetime-local',
        name: 'purchased',
        label: 'Comprado el',
        required: true,
      },
      {
        type: 'textarea',
        name: 'comments',
        label: 'Comentarios',
        required: true,
      },
    ],
    submitForm: 'Añadir nodo',
  },
  assocUnit: {
    endpoints: {
      ADD: 'add-assoc',
      UPDATE: 'update-assoc',
      LIST: 'listing-assoc',
      DELETE: 'del-assoc',
    },
    key: 'assoc',
    singular: 'Asociación',
    plural: 'asociaciones de unidades',
    addNewLabel: 'Añadir nueva asociación',
    hideUpdate: true,
    form: [
      {
        type: 'fetched-select',
        key: 'city',
        name: 'city_id',
        label: 'Ciudad',
        required: true,
        fetcher: async () => post('/listing-cities'),
      },
      {
        type: 'fetched-select',
        label: 'Sistema',
        key: 'system',
        name: 'system_id',
        required: true,
        filterBy: 'city_id',
        fetcher: async () => post('/listing-system'),
      },
      {
        type: 'fetched-select',
        label: 'Sub-sistema',
        key: 'subsystem',
        name: 'subsystem_id',
        required: true,
        filterBy: 'system_id',
        fetcher: async () => post('/listing-subsystem'),
      },
      {
        type: 'fetched-select',
        label: 'Línea',
        key: 'line',
        name: 'line_id',
        required: true,
        filterBy: 'subsystem_id',
        fetcher: async () => post('/listing-line'),
      },
      {
        type: 'fetched-select',
        label: 'Unidad',
        key: 'unit',
        name: 'unit_id',
        required: true,
        filterBy: 'line_id',
        fetcher: async () => post('/listing-unit'),
      },
      {
        type: 'fetched-select',
        label: 'Nodo',
        key: 'node',
        name: 'node_id',
        required: true,
        filterBy: 'unit_id',
        fetcher: async () => post('/available-nodes'),
      },
    ],
    submitForm: 'Añadir asociación',
    columns: [
      {
        type: 'cities',
        title: 'Ciudad',
        nameKey: 'city',
        key: 'city_id',
      },
      {
        type: 'systems',
        title: 'Sistema',
        nameKey: 'system',
        key: 'system_id',
      },
      {
        type: 'subsystems',
        title: 'Sub-sistema',
        nameKey: 'subsystem',
        key: 'subsystem_id',
      },
      {
        type: 'lines',
        title: 'Línea',
        nameKey: 'line',
        key: 'line_id',
      },
      {
        simple: true,
        name: 'serial',
        title: 'Serial',
        key: 'serial',
      },
      {
        simple: true,
        name: 'model',
        title: 'Modelo',
        key: 'model',
      },
    ],
  },
  assoc: {
    endpoints: {
      ADD: 'add-sim-assoc',
      LIST: 'listing-sims-assoc',
      DELETE: 'del-sim-assoc',
    },
    key: 'assoc',
    singular: 'Asociación',
    plural: 'asociaciones de SIMs',
    addNewLabel: 'Añadir nueva asociación',
    hideUpdate: true,
    columns: [
      {
        key: 'icc',
        simple: true,
        title: 'ICC',
      },
      {
        key: 'isdn',
        simple: true,
        title: 'ISDN',
      },
      {
        key: 'company',
        title: 'Compañía',
        simple: true,
      },
      {
        key: 'mac_eth',
        title: 'Mac Ethernet',
        simple: true,
      },
      {
        key: 'mac_w1',
        title: 'Mac W1',
        simple: true,
      },
      {
        key: 'mac_w2',
        title: 'Mac W2',
        simple: true,
      },
      {
        key: 'imei',
        title: 'IMEI',
        simple: true,
      },
      {
        key: 'status',
        title: 'Estatus',
        simple: true,
      },
      // {
      //   key: 'sim',
      //   title: 'SIM',
      //   simple: true,
      // },
      {
        key: 'serial',
        title: 'Serial',
        simple: true,
      },
    ],
    form: [
      {
        type: 'fetched-select',
        label: 'Nodo',
        key: 'node',
        name: 'node_id',
        required: true,
        fetcher: async () => post('/listing-nodes'),
      },
      {
        type: 'fetched-select',
        label: 'SIM',
        key: 'sim',
        name: 'sim_id',
        required: true,
        labelKey: 'icc',
        fetcher: async () => post('/available-sims'),
      },
    ],
    submitForm: 'Añadir asociación',
  },
  availableNodes: {
    endpoints: {
      LIST: 'available-nodes',
      DELETE: 'del-sim-assoc',
    },
    key: 'node',
    singular: 'Nodos disponibles',
    plural: 'Nodos disponibles',
    hideForm: true,
    hideEnabled: true,
    columns: [
      {
        key: 'mac_eth',
        title: 'Mac Ethernet',
        simple: true,
      },
      {
        key: 'mac_w1',
        title: 'Mac W1',
        simple: true,
      },
      {
        key: 'mac_w2',
        title: 'Mac W2',
        simple: true,
      },
      {
        key: 'mac_w2',
        title: 'Mac W2',
        simple: true,
      },
      {
        key: 'imei',
        title: 'IMEI',
        simple: true,
      },
      // {
      //   key: 'sim',
      //   title: 'SIM',
      //   simple: true,
      // },
      {
        key: 'serial',
        title: 'Serial',
        simple: true,
      },
      {
        key: 'comments',
        title: 'Comentarios',
        simple: true,
      },
    ],
  },
};

export default fleetDict;
