/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useState, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { message, Select } from 'antd';
import { filter, map } from 'lodash';
import { slugify } from '../../shared/hooks/useFuzzySearch';

const FetchedSelectComponent = (props, ref) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const {
    label,
    action,
    selector: key,
    onChange,
    labelKey = 'name',
    formValues = {},
    filterBy,
  } = props;

  const filterOpt = useCallback((opt) => {
    const regularFilter = opt.node_id || !!opt.enabled || opt.sim_id;
    if (filterBy) {
      const vals = formValues();
      if (vals[filterBy]) {
        return regularFilter
          && vals[filterBy] === opt[filterBy];
      }
    }

    return regularFilter;
  }, [filterBy, formValues]);

  const fetch = useCallback(async () => {
    setLoading(true);
    try {
      const opts = await action();
      const filteredOpts = filter(opts, filterOpt);
      setOptions(filteredOpts);
    } catch (err) {
      message.error(`Ocurrió un error inesperado: ${err.message}`);
    } finally {
      setLoading(false);
    }
  }, [action, filterOpt]);

  useEffect(() => {
    fetch();
  }, [setOptions, filterBy, formValues, fetch]);

  useImperativeHandle(ref, () => ({
    fetch: () => fetch(),
  }));

  const renderOptions = map(options, (opt) => (
    <Select.Option value={opt[`${key}_id`]} key={opt[key]}>
      {`#${opt[`${key}_id`]} - ${opt[key] || opt[labelKey]}`}
    </Select.Option>
  ));

  return (
    <Select
      loading={loading}
      placeholder={label}
      onChange={onChange}
      showSearch
      filterOption={(input, option) => {
        const sanitizedInput = slugify(input);
        const val = slugify(option.children.toString());
        return val.indexOf(sanitizedInput) > -1;
      }}
    >
      {renderOptions}
    </Select>
  );
};

const FetchedSelect = React.forwardRef(FetchedSelectComponent);

FetchedSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  selector: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formValues: PropTypes.any.isRequired,
  labelKey: PropTypes.string,
};

FetchedSelect.defaultProps = {
  labelKey: 'name',
};

export default FetchedSelect;
