import axios from 'axios';
import { API_URL } from './constants';

const http = axios.create({
  baseURL: API_URL,
});

http.defaults.headers.common['x-api-key'] = 'dKN8fMBz888Ux9ney1iTv7R502UomysHpFbht6S1';

http.interceptors.response.use((response) => response.data);

export const post = (endpoint, data) => http.post(endpoint, data);

export default http;
