import logo from '../assets/logo.svg';

const spinner = require('../assets/spinner.json');

const assets = {
  logo,
  spinner,
};

export default assets;
