import React from 'react';
import { Menu } from 'antd';
import { map } from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import menuItems from '../../shared/constants/menuItems';

const MenuContents = () => {
  const router = useHistory();

  const {
    location: {
      pathname,
    },
  } = router;

  const selected = [pathname];

  const renderMenuItem = (menuItem) => (
    <Menu.Item key={menuItem.path} icon={menuItem.icon} path={menuItem.path}>
      <Link to={menuItem.path}>
        {menuItem.label}
      </Link>
    </Menu.Item>
  );

  const renderItems = () => map(menuItems, (menuItem) => {
    if (menuItem.submenu) {
      return (
        <SubMenu key={menuItem.path} icon={menuItem.icon} title={menuItem.label}>
          {
            map(menuItem.submenu, (submenuItem) => renderMenuItem(submenuItem))
          }
        </SubMenu>
      );
    }
    return renderMenuItem(menuItem);
  });

  return (
    <Menu selectedKeys={selected}>
      {renderItems()}
    </Menu>
  );
};

export default MenuContents;
