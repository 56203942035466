import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { keys, map } from 'lodash';
import { useDispatch } from 'react-redux';
import Error404 from '../pages/404';
import HomePage from '../pages/Home';
import FleetPage from '../pages/Fleet';
import fleetDict from '../shared/constants/fleetDict';
import { getFleetListing } from '../redux/fleet/actions';

const { useLayoutEffect } = React;

const AuthenticatedRoutes = () => {
  const dispatch = useDispatch();
  const availableFleets = keys(fleetDict);

  useLayoutEffect(() => {
    map(availableFleets, (type) => {
      dispatch(getFleetListing(type));
    });
  }, []);

  return (
    <Switch>
      <Route path="/" exact>
        <HomePage />
      </Route>
      <Route path="/fleet/:type" exact>
        <FleetPage />
      </Route>
      <Route path="/users" exact>
        <FleetPage propType="users" />
      </Route>
      <Route path="*" exact>
        <Error404 />
      </Route>
    </Switch>
  );
};

export default AuthenticatedRoutes;
