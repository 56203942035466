import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styles';
import Loader from '../Loader';

const Main = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      {children}
      <Loader />
    </Wrapper>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
