import styled from 'styled-components';

export const Container = styled.div`
  width: 400px;
  margin: 0 auto;
  position: relative;
  height: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.colors.mainGradient};
  background-size: cover;
  background-repeat: no-repeat;
`;

export default Wrapper;
