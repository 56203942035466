import React from 'react';
import { Route } from 'react-router-dom';
import SignIn from '../pages/SignIn';

const GuestRoutes = () => (
  <Route to="*">
    <SignIn />
  </Route>
);

export default GuestRoutes;
