import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Input, Switch, Form, Button, Radio,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { each, isUndefined, map } from 'lodash';
import useFleetAdd from '../../shared/hooks/useFleetAdd';
import FetchedSelect from '../FetchedSelect';

const FleetAdd = (props) => {
  const [formHook] = Form.useForm();
  const { type } = props;
  const { add, dict } = useFleetAdd(type);
  const [x, setForce] = useState(false);
  const [fetchedRefs, setFetchedRefs] = useState([]);
  const { form } = dict;

  const handleSubmit = (values) => {
    add(values);
    formHook.resetFields();
    setForce(!x);
  };

  const setDefaultValues = () => {
    const oldValues = formHook.getFieldsValue();

    formHook.setFieldsValue({});

    each(form, (formElement) => {
      if (isUndefined(formElement.default)) {
        return;
      }

      let defaultValue = formElement.default;

      if (formElement.type === 'boolean') {
        defaultValue = defaultValue ? 1 : 0;
      }

      const oldValue = oldValues[formElement.name];

      formHook.setFieldsValue({
        [formElement.name]: isUndefined(oldValue) ? defaultValue : oldValue,
      });
    });
  };

  const addFetchedSelectRef = (ref, formElement) => {
    if (!ref) {
      return;
    }
    if (fetchedRefs.find((fr) => fr.em.key === formElement.key)) {
      return;
    }
    setFetchedRefs([
      ...fetchedRefs,
      {
        ref,
        em: formElement,
      },
    ]);
  };

  const refetchOther = (formElement) => {
    const other = fetchedRefs.filter((fr) => fr.em.key !== formElement.key);
    other.forEach((ref) => {
      if (
        ref
          && ref.ref
          && ref.ref.fetch
      ) {
        ref.ref.fetch();
      }
    });
  };

  useEffect(() => {
    setDefaultValues();
  }, [form, type, x]);

  const renderBoolean = (formElement) => (
    <Form.Item key={formElement.name} name={formElement.name} label={formElement.label}>
      <Switch
        onChange={(v) => {
          formHook.setFieldsValue({ [formElement.name]: v ? 1 : 0 });
          setForce(!x);
        }}
        defaultChecked={!!formElement.default}
      />
    </Form.Item>
  );

  const renderInput = (formElement) => (
    <Form.Item
      key={formElement.name}
      name={formElement.name}
      rules={[{ required: !!formElement.required, message: 'Este campo es requerido.' }]}
    >
      <Input size="medium" type={formElement.type} placeholder={formElement.label} maxLength={formElement.maxLength || Infinity} />
    </Form.Item>
  );

  const renderTextarea = (formElement) => (
    <Form.Item
      key={formElement.name}
      name={formElement.name}
      rules={[{ required: !!formElement.required, message: 'Este campo es requerido.' }]}
    >
      <Input.TextArea size="medium" type={formElement.type} placeholder={formElement.label} autoSize />
    </Form.Item>
  );

  const renderFetchedSelect = (formElement) => (
    <Form.Item
      key={formElement.key}
      name={formElement.name}
      rules={[{ required: !!formElement.required, message: 'Este campo es requerido.' }]}
    >
      <FetchedSelect
        ref={(ref) => addFetchedSelectRef(ref, formElement)}
        onChange={(v) => {
          formHook.setFieldsValue({ [formElement.key]: v });
          refetchOther(formElement);
        }}
        action={formElement.fetcher}
        filterBy={formElement.filterBy}
        formValues={() => formHook.getFieldsValue()}
        label={formElement.label}
        selector={formElement.key}
        labelKey={formElement.labelKey}
      />
    </Form.Item>
  );

  const renderCombo = (formElement) => (
    <Form.Item
      key={formElement.name}
      name={formElement.name}
      label={formElement.label}
      rules={[{ required: !!formElement.required, message: 'Este campo es requerido.' }]}
    >
      <Radio.Group>
        {
        map(formElement.options, (option) => (
          <Radio.Button value={option}>
            {option}
          </Radio.Button>
        ))
      }
      </Radio.Group>
    </Form.Item>
  );

  const shouldShowElement = (formElement) => {
    if (!formElement.if) {
      return true;
    }

    const { if: ifCondition } = formElement;
    const value = formHook.getFieldValue(ifCondition);

    return !!value;
  };

  const renderElement = (formElement) => {
    const { type: elementType } = formElement;

    if (!shouldShowElement(formElement)) {
      return null;
    }

    let element;
    switch (elementType) {
      case 'boolean':
        element = renderBoolean(formElement);
        break;
      case 'textarea':
        element = renderTextarea(formElement);
        break;
      case 'fetched-select':
        element = renderFetchedSelect(formElement);
        break;
      case 'combo':
        element = renderCombo(formElement);
        break;
      default:
        element = renderInput(formElement);
        break;
    }

    return element;
  };

  const elements = map(form, (formElement) => renderElement(formElement));

  return (
    <Card>
      <Title level={4}>{dict.addNewLabel}</Title>
      <Form style={{ marginTop: 30 }} onFinish={handleSubmit} form={formHook}>
        {elements}
        <Form.Item>
          <Button type="primary" htmlType="submit">{dict.submitForm}</Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

FleetAdd.propTypes = {
  type: PropTypes.oneOf(['cities', 'systems', 'subsystems', 'lines', 'units', 'nodes']).isRequired,
};

export default FleetAdd;
