import React from 'react';
import {
  BranchesOutlined,
  BoxPlotOutlined,
  BuildOutlined,
  FundOutlined,
  HeatMapOutlined,
  HomeOutlined,
  RadarChartOutlined,
  UngroupOutlined,
  WifiOutlined,
  MehOutlined,
  SubnodeOutlined,
  UserOutlined,
} from '@ant-design/icons';

const menuItems = [
  {
    label: 'Inicio',
    icon: <HomeOutlined />,
    path: '/',
  },
  {
    label: 'Flota',
    icon: <SubnodeOutlined />,
    submenu: [
      {
        label: 'Ciudades',
        icon: <BuildOutlined />,
        path: '/fleet/cities',
      },
      {
        label: 'Sistemas',
        icon: <HeatMapOutlined />,
        path: '/fleet/systems',
      },
      {
        label: 'Subsistemas',
        icon: <RadarChartOutlined />,
        path: '/fleet/subsystems',
      },
      {
        label: 'Líneas',
        icon: <FundOutlined />,
        path: '/fleet/lines',
      },
      {
        label: 'Unidades',
        icon: <BranchesOutlined />,
        path: '/fleet/units',
      },
      {
        label: 'SIMs',
        icon: <WifiOutlined />,
        path: '/fleet/sims',
      },
      {
        label: 'Nodos',
        icon: <UngroupOutlined />,
        path: '/fleet/nodes',
      },
      {
        label: 'Asociaciones Unidades',
        icon: <BoxPlotOutlined />,
        path: '/fleet/assocUnit',
      },
      {
        label: 'Asociaciones SIM',
        icon: <BoxPlotOutlined />,
        path: '/fleet/assoc',
      },
      {
        label: 'Nodos disponibles',
        icon: <MehOutlined />,
        path: '/fleet/availableNodes',
      },
    ],
  },
  {
    label: 'Usuarios',
    icon: <UserOutlined />,
    path: '/users',
  },
];

export default menuItems;
