import React from 'react';
import { LockOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import FullPage from '../../components/FullPage';
import SignForm from '../../components/SignForm';
import { SignInButton } from './styles';

const SignIn = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <FullPage>
      <SignForm title="Inicia sesión">
        <SignInButton onClick={() => loginWithRedirect()}>
          <LockOutlined />
          Continuar
        </SignInButton>
      </SignForm>
    </FullPage>
  );
};

export default SignIn;
