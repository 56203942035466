import { useDispatch } from 'react-redux';
import { addFleetElement } from '../../redux/fleet/actions';
import fleetDict from '../constants/fleetDict';

const useFleetAdd = (type) => {
  const dict = fleetDict[type];
  const dispatch = useDispatch();

  const add = (data) => {
    dispatch(addFleetElement(type, data));
  };

  return {
    add,
    dict,
  };
};

export default useFleetAdd;
