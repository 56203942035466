import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  margin: 0 auto 2rem;
`;

export const Title = styled.div`
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: 600;
  color: ${(props) => props.theme.colors.main};
`;

export const Logo = styled.img`
  width: 50%;
  display: block;
  margin: 2rem auto;
`;

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  background: ${(props) => props.theme.colors.light};
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.075);
  border-radius: 5px;

  animation: signFormAnimation 1s ease;
  animation-fill-mode: both;

  @keyframes signFormAnimation {
    from {
      opacity: 0;
      transform: translate(-5px, -55%) rotate(-2deg);
    }
    to {
      opacity: 1;
      transform: translate(0, -50%);
    }
  }
`;

export default Wrapper;
