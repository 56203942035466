/* eslint-disable import/prefer-default-export */
import { message } from 'antd';
import { isUndefined } from 'lodash';
import fleetDict from '../../shared/constants/fleetDict';
import { post } from '../../shared/http';
import {
  ADD_FLEET_ELEMENT, GET_FLEET_LISTING, UPDATE_FLEET_ELEMENT,
} from './constants';

export const getFleetListing = (type) => {
  const dict = fleetDict[type];
  const {
    endpoints: {
      LIST: listEndpoint,
    },
  } = dict;

  return {
    types: GET_FLEET_LISTING,
    fleetType: type,
    action: async () => post(`/${listEndpoint}`),
  };
};

export const addFleetElement = (type, data) => {
  const d = { ...data };
  const dict = fleetDict[type];

  if (isUndefined(d.enabled)) {
    d.enabled = 1;
  }

  if (isUndefined(d.lon)) {
    d.lon = '0';
  }

  if (isUndefined(d.lat)) {
    d.lat = '0';
  }

  const {
    endpoints: {
      ADD: addEndpoint,
    },
  } = dict;

  return {
    types: ADD_FLEET_ELEMENT,
    fleetType: type,
    action: async () => post(`/${addEndpoint}`, d),
    onSuccess: (dispatch) => {
      dispatch(getFleetListing((type)));
      message.success(`${dict.singular} añadido con éxito.`);
    },
    onError: (dispatch) => {
      dispatch(getFleetListing((type)));
    },
  };
};

export const updateFleetElement = (type, rowData) => {
  const dict = fleetDict[type];

  const sendData = { ...rowData };
  const formattedId = `${dict.key}_id`;
  sendData[formattedId] = rowData.id;
  sendData[dict.key] = rowData.name;

  const {
    endpoints: {
      UPDATE: updateEndpoint,
    },
  } = dict;

  return {
    types: UPDATE_FLEET_ELEMENT,
    fleetType: type,
    action: async () => post(`/${updateEndpoint}`, sendData),
    onSuccess: (dispatch) => {
      dispatch(getFleetListing((type)));
      message.success(`${dict.singular} editado con éxito.`);
    },
    onError: (dispatch) => {
      dispatch(getFleetListing((type)));
    },
  };
};

export const deleteFleetElement = (type, rowData) => {
  const dict = fleetDict[type];

  const sendData = { ...rowData };
  const formattedId = `${dict.key}_id`;
  sendData[formattedId] = rowData.id;
  sendData[dict.key] = rowData.name;

  const {
    endpoints: {
      DELETE: deleteEndpoint,
    },
  } = dict;

  return {
    types: UPDATE_FLEET_ELEMENT,
    fleetType: type,
    action: async () => post(`/${deleteEndpoint}`, sendData),
    onSuccess: (dispatch) => {
      dispatch(getFleetListing((type)));
      message.success(`${dict.singular} borrado con éxito.`);
    },
    onError: (dispatch) => {
      dispatch(getFleetListing((type)));
    },
  };
};

export const loadAllTypes = (dispatch) => {
  const fleets = Object.keys(fleetDict);

  fleets.forEach((type) => {
    dispatch(getFleetListing(type));
  });
};
