import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import Wrapper, { AnimationContainer } from './styles';
import assets from '../../shared/assets';

const Loader = () => {
  const { isLoading } = useAuth0();
  const { show } = useSelector((reduxState) => reduxState.loader);

  const shouldDisplay = isLoading || show;

  if (!shouldDisplay) {
    return null;
  }

  return (
    <Wrapper>
      <AnimationContainer>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: assets.spinner,
          }}
        />
      </AnimationContainer>
    </Wrapper>
  );
};

export default Loader;
