/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Card,
  Space,
  Switch,
  Table,
  Input,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import {
  isUndefined, map, isFunction, isEmpty,
} from 'lodash';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import useFleetListing from '../../shared/hooks/useFleetListing';
import antdTranslations from '../../shared/constants/antdTranslations';
import { deleteFleetElement, updateFleetElement } from '../../redux/fleet/actions';

import 'moment/locale/es';
import EditableCell from '../EditableCell';

import { TableWrapper } from './styles';
import { useFuzzySearchByKeys } from '../../shared/hooks/useFuzzySearch';

const { Search } = Input;

const FleetListing = (props) => {
  const { type } = props;

  const dispatch = useDispatch();

  const { fleet } = useSelector((reduxState) => reduxState);

  const [rawData, setRawData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [x, setForceX] = useState(false);

  const {
    fetch,
    loading,
    dict,
    payload,
  } = useFleetListing(type, [type]);

  useEffect(() => {
    fetch();
    setForceX((_x) => !_x);
  }, [type]);

  useEffect(() => {
    setRawData(payload);
  }, [x, payload, type]);

  const handleRowEnabledChange = (rowData, isEnabled) => {
    const toUpdateData = rowData;
    toUpdateData.enabled = isEnabled ? 1 : 0;

    dispatch(updateFleetElement(type, toUpdateData));
  };

  const handleDelete = (rowData) => {
    dispatch(deleteFleetElement(type, rowData));
  };

  const data = useMemo(() => {
    const filteredData = useFuzzySearchByKeys(rawData, searchValue);

    if (isEmpty(filteredData)) {
      return [];
    }

    return map(filteredData, (row) => ({
      ...row,
      id: row[`${dict.key}_id`],
      name: row[dict.key] || row.name,
      enabled: row.enabled,
      created: row.created_at,
    }));
  }, [x, rawData, type, searchValue]);

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const updateName = (record, name) => {
    const r = { ...record };
    r.name = name;
    dispatch(updateFleetElement(type, r));
  };

  const updateValue = (record, value, key) => {
    const r = { ...record };
    r[key] = value;
    dispatch(updateFleetElement(type, r));
  };

  const apiColumns = () => {
    const columns = [];

    dict.columns?.forEach((column) => {
      columns.push({
        title: column.title,
        dataIndex: column.key,
        key: column.key,
        width: column.width || 50,
        render: (text, recordData) => {
          if (isFunction(column.render)) {
            return column.render(text, recordData);
          }

          if (column.editable) {
            return (
              <EditableCell
                editableCombo={column.editableCombo}
                defaultValue={text}
                onSave={(value) => updateValue(recordData, value, column.key)}
              />
            );
          }

          if (column.simple) {
            return text;
          }

          const colValues = fleet[column.type];
          // eslint-disable-next-line eqeqeq
          const record = colValues.find((_record) => _record[column.key] == text);
          return (record && record[column.nameKey]) || text;
        },
      });
    });

    return columns;
  };

  const columns = (() => {
    const shouldHideEnabled = dict.hideUpdate || dict.hideEnabled;
    const cols = [
      {
        title: '#',
        dataIndex: 'id',
        key: 'id',
        visible: !dict.hideDefaultColumns,
      },
      {
        title: dict.singular,
        dataIndex: 'name',
        key: 'name',
        visible: !dict.hideDefaultColumns,
        render: (_text, record) => {
          const text = record[dict.key] || _text;
          if (!text) {
            return '';
          }
          if (dict.disableNameEdition) {
            return text;
          }
          return (
            <EditableCell
              defaultValue={text}
              onSave={(value) => updateName(record, value)}
            />
          );
        },
      },
      ...apiColumns(),
      {
        title: 'Habilitado',
        dataIndex: 'enabled',
        key: 'enabled',
        visible: !shouldHideEnabled,
        render: (enabled, rowData) => {
          if (dict.disableEnabled) {
            return (
              enabled
                ? <CheckCircleFilled />
                : <CloseCircleFilled color="red" />
            );
          }
          return (
            <Switch
              checked={enabled}
              enabled={!dict.disableEnabled}
              onChange={(isEnabled) => handleRowEnabledChange(rowData, isEnabled)}
            />
          );
        },
      },
      {
        title: 'Creación',
        dataIndex: 'created',
        key: 'created',
        render: (created) => moment(created).format('LLLL'),
      },
      {
        title: 'Acciones',
        key: 'action',
        visible: !dict.hideDelete,
        render: (text, record) => {
          if (!dict.hideDelete) {
            return (
              <Space size="medium">
                <a onClick={() => handleDelete(record)} role="button">
                  Borrar
                </a>
              </Space>
            );
          }
          return <></>;
        },
      },
    ];

    return cols.filter((col) => isUndefined(col.visible) || col.visible);
  })();

  return (
    <Card>
      <Title level={4}>{`Listado de ${dict.plural}`}</Title>
      <Search
        placeholder="Filtra"
        onSearch={handleSearch}
        onChange={({ target }) => handleSearch(target.value)}
      />
      <TableWrapper>
        <Table
          pagination={{ pageSize: 100 }}
          loading={loading}
          locale={antdTranslations.Table}
          columns={columns}
          dataSource={data}
        />
      </TableWrapper>
    </Card>
  );
};

FleetListing.propTypes = {
  type: PropTypes.oneOf(['cities', 'systems', 'subsystems', 'lines', 'units', 'nodes']).isRequired,
};

export default FleetListing;
