import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Main from './components/Main';
import store from './redux/store';
import MainRouter from './routes';
import theme from './shared/theme';
import { AUTH0_DOMAIN, AUTH0_ID } from './shared/constants';

const App = () => (
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_ID}
    redirectUri={window.location.origin}
  >
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Main>
          <MainRouter />
        </Main>
      </ThemeProvider>
    </Provider>
  </Auth0Provider>
);

export default App;
