import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import FleetListing from '../../components/FleetListing';
import withLayout from '../../shared/HOC/withLayout';
import Wrapper from './styles';
import FleetAdd from '../../components/FleetAdd';
import useFleetListing from '../../shared/hooks/useFleetListing';

const FleetPage = ({ propType }) => {
  const params = useParams();
  const { type = propType } = params;

  const {
    dict,
  } = useFleetListing(type, [type]);

  const hasForm = useMemo(() => !dict.hideForm, [dict]);

  return (
    <Wrapper>
      <Row gutter={24}>
        <Col span={hasForm ? 16 : 24}>
          <FleetListing type={type} />
        </Col>
        {
          hasForm && (
            <Col span={8}>
              <FleetAdd type={type} />
            </Col>
          )
        }
      </Row>
    </Wrapper>
  );
};

FleetPage.propTypes = {
  propType: PropTypes.oneOf(['users']),
};

FleetPage.defaultProps = {
  propType: undefined,
};

export default withLayout(FleetPage);
