import styled from 'styled-components';

export const UserInfo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Logo = styled.img`
  width: 120px;
  display: block;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
`;

export default Wrapper;
