import { each } from 'lodash';

const generateTypes = (type) => {
  const types = ['REQUEST', 'SUCCESS', 'ERROR'];
  const output = {};

  each(types, (t) => {
    output[t] = `${type}_${t}`;
  });

  return output;
};

export default generateTypes;
