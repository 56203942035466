/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const TableWrapper = styled.div`
  overflow: hidden;

  .ant-table-container {
    overflow-x: auto;
  }
`;
