/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Layout, { Content, Header } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import HeaderContents from '../../components/HeaderContents';

import './withLayout.scss';
import MenuContents from '../../components/Menu';

const withLayout = (Component) => (props) => (
  <Layout>
    <Header className="layout-header">
      <HeaderContents />
    </Header>
    <Layout>
      <Sider
        className="layout-sidebar"
      >
        <MenuContents />
      </Sider>
      <Layout style={{ marginLeft: 200 }}>
        <Content className="layout-content">
          <Component {...props} />
        </Content>
      </Layout>
    </Layout>
  </Layout>
);

export default withLayout;
