const initialState = {
  loading: true,
  hasInitialLoaded: false,
  cities: [],
  systems: [],
  subsystems: [],
  lines: [],
  units: [],
  nodes: [],
};

const fleet = (state = initialState, action) => {
  const newState = { ...state };
  newState.loading = action.loading;
  if (action.payload) {
    newState[action.fleetType] = action.payload;
  }

  return newState;
};

export default fleet;
