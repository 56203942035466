import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import apiMiddleware from './apiMiddleware';

import loader from './loader/reducer';
import fleet from './fleet/reducer';

const rootReducer = combineReducers({
  loader,
  fleet,
});

const store = createStore(
  rootReducer,
  applyMiddleware(apiMiddleware, thunk),
);

export default store;
