import styled from 'styled-components';

export const AnimationContainer = styled.div`
  width: 110px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: rgba(255,255,255,0.9);
  pointer-events: none;
`;

export default Wrapper;
